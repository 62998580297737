<template>
  <div>
    <tgl-card-header-mobile
      ><h1>{{ notification.timestamp }}</h1></tgl-card-header-mobile
    >
    <tgl-card-content-mobile>
      {{ notification.message }}
    </tgl-card-content-mobile>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TglCardHeaderMobile from "@/components/Mobile/Cards/TglCardHeaderMobile.vue";
import TglCardContentMobile from "@/components/Mobile/Cards/TglCardContentMobile.vue";
export default {
  data: () => ({
    notification: {},
  }),
  components: {
    TglCardHeaderMobile,
    TglCardContentMobile,
  },
  mounted() {
    this.$store
      .dispatch("notification/getNotification", this.$route.params.notification)
      .then((notification) => (this.notification = notification));
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  watch: {
    "$route.params.notification"() {
      this.$store
        .dispatch(
          "notification/getNotification",
          this.$route.params.notification
        )
        .then((notification) => (this.notification = notification));
    },
  },
};
</script>
